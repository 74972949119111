import {
  EmailShareButton, EmailIcon,
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  PinterestShareButton, PinterestIcon,
  LinkedinShareButton, LinkedinIcon,
  RedditShareButton, RedditIcon
} from 'react-share';

import {useLocation} from "react-router-dom";
import Config from "../../helpers/Config";

function ModalShare(){
  const iconSize = 45;
  const quote = 'Utrecht Psalter - annotated edition';

  const {pathname} = useLocation();
  const shareUrl = Config('VIEWER_URL') + pathname;

  return (
    <div className="ubu-share-panel">

      <EmailShareButton url={shareUrl} quote={quote} >
        <EmailIcon  size={iconSize} />
      </EmailShareButton>
      <TwitterShareButton url={shareUrl} quote={quote} >
        <TwitterIcon  size={iconSize} />
      </TwitterShareButton>
      <FacebookShareButton url={shareUrl} quote={quote} >
        <FacebookIcon size={iconSize} />
      </FacebookShareButton>
      <PinterestShareButton url={shareUrl} quote={quote}  >
        <PinterestIcon size={iconSize} />
      </PinterestShareButton>
      <LinkedinShareButton url={shareUrl} quote={quote} >
        <LinkedinIcon size={iconSize} />
      </LinkedinShareButton>
      <RedditShareButton url={shareUrl} quote={quote} >
        <RedditIcon size={iconSize} />
      </RedditShareButton>
    </div>
  )
}


export default ModalShare;
