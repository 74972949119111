import {Link, useLocation} from "react-router-dom";

/**
 * Gemaakt met de buitengewone bijdrage van Ruben Rubini Marcozzi
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function PageNotFound() {

  let location = useLocation();


  return (
    <div id='not-found'>
      <div className={"not-found-text"}>
        <h2>Page not found.</h2>
        <br/>
        <p>
          Sorry, the page <span className={'page'}>{location.pathname}</span> could not be found.
        </p>
        <p>
          <Link to={'/page/1'}>Go back to the first page</Link>
        </p>
      </div>
    </div>

  );

}
