const GeneralInfo = (
  <div className="info-text">
    <p>In the Utrecht Psalter each of the 150 psalms and 16 biblical songs and prayers have been illustrated in an
      exceptional way. In each case the illustration of the psalm precedes the psalm text it belongs to.</p>

    <p>This <b>annotated edition</b> of the Utrecht Psalter presents the manuscript in high resolution and explains
      the way in which the illustrations relate to the texts.</p>

    <p>Read more  <a
        href="https://www.uu.nl/en/special-collections/the-treasury/manuscripts-from-the-treasury/the-utrecht-psalter"
      >about the Utrecht Psalter</a>.</p>

    <p><b>We appreciate your feedback!</b></p>

    <p><i className="fas fa-envelope"/> <a href="mailto:bibliotheek@uu.nl?subject=Question about Psalter annotated edition:"> bibliotheek@uu.nl</a></p>

    <p><i className="fab fa-twitter"/> <a href="https://twitter.com/uniutrechtlib">@UniUtrechtLib</a>
    </p>

    <br/>

    <h4>Explaining the illustrations</h4>

    <p className="info-title">
      <i className="fa fa-file-alt fa-bw"/><strong>Text</strong></p>

    <p>The text of the psalms or cantica are available in four languages: English, Dutch, French and Latin. A
      rectangle on the image links that specific illustration to a specific part or parts of the psalm text, and vice
      versa. You can either click on the rectangle or the text to discover the connection. The texts, including the
      Latin, are taken from standard translations. The Latin text is the standard Vulgate text as found in modern
      editions. The translations are also standard; see 'About this edition' for more information.</p>

    <p className="info-title">
      <i className="fa fa-comment"/><strong>Description</strong></p>
    <p> The description of image describes the illustration, explaining what is depicted there.</p>

    <p className="info-title">
      <i className="fa fa-pen-square"/><strong>Additional information</strong></p>

    <p>The additional information lists academic publications on the illustration of a particular psalm text in the
      Utrecht Psalter or describes other notable features in the manuscript. A rectangle on the image links these
      notable features to the description, and vice versa.</p>
    <p> The psalms and cantica are available in four languages. </p>

    <h4>Navigation</h4>

    <p> You can browse the Utrecht Psalter by using the thumbnails bar at the bottom and the previous / next buttons
      on both sides of the central thumbnail.</p>

    <h5><strong>Navigation box</strong></h5>
    <p className="info-title info-icon">
      <i className="fa fa-angle-double-down"> </i>0 of 256
      <i className="fa fa-external-link-square-alt"/>
    </p>
    <p>The navigation box will take you to a specific folio, psalm or canticum. Click on the arrow.</p>

    <p>A link to a psalm or canticum always takes you to where that psalm or canticum is illustrated. The illustration
      precedes the text in the manuscript.</p>


    <h4>Expanding your view</h4>

    <p>We offer you the best possible view of the manuscript. You can:</p>

    <p><i
      className="fa fa-angle-double-right fa-bw" /> Hide the annotation
      panel with the arrow in the upper right corner.</p>


    <p><i className="fa fa-expand fa-bw"/> Go to full screen.</p>

    <h4>Share the Utrecht Psalter</h4>

    <p> Sharing the Utrecht Psalter with others is much appreciated. You can:</p>

    <p><i className="fa fa-share-alt fa-bw"/> Share the image on social media.</p>

    <p><i className="fa fa-download fa-bw"/> Download the image in three different sizes.</p>

  </div>
);

const AboutInfo = (
  <div>
    <h4><strong>Introduction to the website of the annotated edition of the Utrecht Psalter</strong></h4>


    <p><strong>Preface</strong></p>

    <p>The annotated edition is the successor of the CD-ROM <em>The Utrecht Psalter. Picturing the Psalms of
      David</em>, which was issued in 1996. It was available online from 2002 to 2011. As both the CD-ROM and online
      version became outdated, it was decided to digitize the Utrecht Psalter anew, from cover to cover. In October
      2013 this digitized version of the Utrecht Psalter, without any annotation, was presented <a
        href="https://objects.library.uu.nl/reader/index.php?obj=1874-284427&lan=en#page//11/51/45/11514575807329943918974580038627186786.jpg/mode/1up"
      >online</a>, with a short introductory <a
        href="https://www.uu.nl/en/utrecht-university-library-special-collections/the-treasury/manuscripts-from-the-treasury/the-utrecht-psalter"
      >text</a>.</p>

    <p>The new annotated edition of the Utrecht Psalter, online since June 2015, is incorporated in the website <a
      href="https://psalter.library.uu.nl"
    >psalter.library.uu.nl</a>.
      Compared with the CD-ROM edition, the layout has been changed considerably and several features have been
      improved or added.</p>

    <p><strong>Text and image</strong></p>

    <p>The main goal of the new annotated edition remains the same as that of the CD-ROM: to establish the link
      between text and image. The user can click on a section of the psalter text that is illuminated, and the
      relevant part of the illustration is highlighted &ndash; and vice versa. As only rectangular shapes are
      possible, the frame may not capture the whole image or include parts that are not related to the selected
      text.</p>

    <p>Other notable features in the manuscript are highlighted and explained as well, such as the cover with Robert
      Cotton&rsquo;s family crest, the list of contents written in Cotton&rsquo;s time, pen trials, additional
      drawings in the margin, textual corrections and features of the Northumbrian gospel fragments (fol. 94-105)
      which Cotton bound with the psalter.</p>

    <p className="info-title"><i className="fa fa-file-alt"/><strong>Four translations of the psalter</strong></p>

    <p>The 150 psalms were originally written in Hebrew. We present four translations, in Latin, English, Dutch and
      French, of the psalms and the sixteen <em>cantica</em>. These translations were taken from the CD-ROM edition.
      The translations used are the following:</p>

    <p>Latin: the standard <a href="https://en.wikipedia.org/wiki/Vulgate"
    >Vulgate</a>
      text. It should be noted that the Utrecht Psalter gives the so-called Gallican version of the Vulgate, which
      differs at various points from the standard Vulgate text by Jerome as reconstructed by modern scholarship. These
      differences are mostly of minor importance and do not affect the meaning of the text. They have not been
      incorporated in the standard Vulgate text as is presented here.</p>

    <p>English: The Book of Psalms in <em>The Holy Bible translated from the Latin Vulgate, diligently compared with
      the Hebrew, Greek and other editions in divers languages. The Old Testament first published by the English
      College at Douay, A.D. 1609, and the New Testament first published by the English College at Rheims, A.D.
      1582</em>. 2 vols (Turnhout, 1912), pp. 567-643. This edition is based on the Douay translation of the Old
      Testament of 1609 as revised by <a href="https://en.wikipedia.org/wiki/Richard_Challoner"
      >Richard
        Challoner</a> in 1750.</p>

    <p>Dutch: <em>Het Boek der Psalmen</em> (Bruges, 1941). This is the edition of Achilleus vander Heeren, from the
      original publication of the Latin Vulgate with a Dutch translation by Joannes Theodorus Beelen, first published
      in 1878. For the CD-ROM edition the spelling was modernized.</p>

    <p>French: <em>Le livre des Psaumes suivi des cantiques des laudes et des v&ecirc;pres. Traduction nouvelle sur la
      Vulgate </em><em>avec introduction, sommaire et notes</em> (Tournai, 1889). The translation of Augustin Crampon,
      which was re-edited and published in Tournai in 1930.</p>

    <p>This list differs somewhat from the one given in the booklet of the CD-ROM, which contains a couple of
      mistakes.</p>

    <p>The translations of the psalter into the three modern languages all derive from those made in the nineteenth
      century or earlier, and use old-fashioned words, phrases and even spelling. Yet as there are no recent
      translations of the Vulgate, and because it is important to stay close to the (literal) meaning of the Latin
      text in order to appreciate how the psalms are illustrated in the Utrecht Psalter, these translations have been
      retained.</p>

    <p>The numbering of the psalms is that of the Vulgate. Note that the numbering in the manuscript (in which the
      text follows the image of a particular psalm) is mistaken through scribal errors in nine cases (psalms 47, 64,
      66, 67, 73, 74, 78, 89, 112). The Hebrew (and Protestant) numbering differs from the Vulgate numbering (H 1-8 =
      V 1-8; H 9 = V 9:1-21; H 10 = V 9:22-end; H 11-113 = V 10-112; H 114 = V 113:1-8; H 115 = V 113:9-end; H 116:1-9
      = V 114; H 116:10-end = V 115; H 117-146 = V 116-145; H 147:1-11 = V 146; H 147:12-end = V 147; H 148-150 = V
      148-150).</p>

    <p>The 16 cantica are the following: 1. Confitebor&nbsp;tibi (Is. 12:1-6); 2. Ego dixi in dimidio (Is. 38:10-12);
      3. Exsultavit cor meum (1 Reg/Sam 2:1-10); 4. Cantemus domino (Ex. 15:1-19); 5. Domine audivi (Hab. 3:2-19); 6.
      Audite caeli (Deut. 32:1-43); 7. Benedicite omnia (Dan. 3:57-88,56); 8. Te deum laudamus; 9. Benedictus Dominus
      (Luke 1:68-79); 10. Magnificat anima mea; 11. Nunc dimittis (Luke 2:29-32); 12. Gloria in excelsis Deo; 13.
      Pater noster; 14. Credo in Deum patrem (Apostles&rsquo; Creed); 15. Quicumque vult (Athanasian Creed); 16.
      Pusillus eram (apocryphal psalm 151).</p>

    <p>The texts can be searched.</p>

    <p>&nbsp;</p>

    <p className="info-title"><i className="fa fa-comment"/><strong>The description of the images</strong></p>
    <p>Just as with the CD-ROM, we include the descriptions of the images as published in E. T. DeWald, <em>The
      illustrations of the Utrecht Psalter</em> (Princeton, [1932]), pp. 3-73, with some additions and corrections
      that were included in the CD-ROM version. However, the footnotes have been incorporated in the descriptions
      themselves, while four references to secondary literature have been omitted since the information is not up to
      date anymore. It should also be noted that the translations from scripture, including the psalter which DeWald
      cites, may differ from the English translation of the Vulgate used here.</p>
    <p>DeWald often uses the term Christ-Logos, which he explains as follows (p. 5, n. 3): &lsquo;God is frequently
      represented in early Christian and mediaeval iconography with the attributes of Christ, particularly in Old
      Testament scenes, i.e. scenes prior to Christ&rsquo;s incarnation, e.g. Christ (God) created Adam. This
      representation of Christ as the Word is arbitrarily called by the Princeton Index of Christian Art
      the &ldquo;Christ-Logos.&rdquo;&rsquo;</p>
    <p>One of the problems with DeWald is that he is not always consistent. For example, in the textually almost
      identical psalms 13 (fol. 7v) and 52 (fol. 30v), which are also illustrated much alike in the Utrecht Psalter
      (albeit by two different draughtsmen), the man with a sword on his lap under a canopy is described by DeWald for
      psalm 13 as &lsquo;a ruler&rsquo;, but for psalm 52 as &lsquo;a judge or prince&rsquo;, although it clearly
      concerns the same person. Similarly, in psalms 95 and 96 (fol. 56r and 56v) angels each time hold an identical
      instrument, which in the first is described as &lsquo;balances&rsquo;, in the second as &lsquo;a pair of
      scales&rsquo;.
      In his printed index DeWald only has the lemmas &lsquo;prince&rsquo; and &lsquo;scales&rsquo;. Such varieties in
      defining the image of a certain person or object has to be taken into account when searching the descriptions. A
      whole new description is desirable, but is not foreseen in the near future.</p>

    <p className="info-title"><i className="fa fa-pen-square"/><strong>Additional information: references to
      secondary
      literature</strong></p>
    <p>A new feature is the list of references to secondary literature which is given for each individual psalm or
      canticum. Each list is in chronological order, and where possible gives the exact pagenumber(s) the psalm in
      question as illustrated in the Utrecht Psalter is discussed. An extensive bibliography of secondary literature
      in which the Utrecht Psalter is discussed can be found here: <b>
        <a
          href="https://www.uu.nl/en/utrecht-university-library-special-collections/the-treasury/manuscripts-from-the-treasury/the-utrecht-psalter"
          target="_blank" rel="noreferrer"
        >Utrecht Psalter on Utrecht University Library Special Collections</a>
      </b>.
    </p>

    <p><strong>Further reading</strong></p>

    <p>Bart Jaski, &lsquo;Reproductions of the Utrecht Psalter: handwritten, printed and digital&rsquo;, in: <em>Jaarboek
      voor Nederlandse boekgeschiedenis </em>22 (2015), 101-121.</p>
  </div>
);

const SomeInfo = (
  <div className="info-text">
    <p>In the Utrecht Psalter each of the 150 psalms and 16 biblical songs and prayers have been illustrated in an
      exceptional way. In each case the illustration of the psalm precedes the psalm text it belongs to.</p>
  </div>
);

export {GeneralInfo, AboutInfo, SomeInfo}
