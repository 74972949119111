import {useEffect, useState} from "react";
import {getIndex} from "../services/ObjectsApi";


function useBookIndexData() {

  const [loading, setLoading] = useState(true );
  const [error, setError] = useState(null);
  const [indexData, setIndexData] = useState({});

  useEffect(() => {

    async function loadData() {
      try {
        const response = await getIndex();
        setIndexData(response.data.pageData.page);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
    loadData()
  }, []);

  if (error) throw error;

  return {
    indexIsLoading : loading ,
    indexData
  }
}

export default useBookIndexData;

