import {iterable, iterableSections} from "../helpers/Utils"
import {isEmpty} from "lodash";

/**
 * Gets a collection of page coordinates
 * each coordinates consist in:
 *
 *  - identifier : string reference to coord set (036962025737006404007775)
 *
 *  - group : array reference to annotation text  !
 *            !!!A group contains reference to all the languages
 *  - section: id of section 0,1,2  this is used to match the right tab when
 *              clicking on a coordinate whose text is not on a visible tab
 *
 *
 *  Example:
 {
            "033393044324015302013716": {
                "type": "rect",
                "x": "0.33393",
                "y": "0.44324",
                "w": "0.15302",
                "h": "0.13716",
                "id": "033393044324015302013716",
                "identifier": "033393044324015302013716",
                "groups": [
                    "transcription-001",
                    "transcription-011",
                    "transcription-021",
                    "transcription-031"
                ],
                "section": 0
            },
            "031773035608007921004189": {
            ...
            }
        }
 *
 *
 * @param pageContent = section.section
 * @param sectionName
 * @returns {*}
 */
function getCoordsData(pageContent, sectionName){

  let coords = {};

  //TODO check this
  const textPrefix = 'transcription-';

  /*
   * Returns a string representing the coordinates
   *
   * @param coords
   * @returns string
   */
  function getCoordsId(coords) {
    return (coords.x + coords.y + coords.w + coords.h).replace(/\./g, '');
  }

  /*
   * Register Coordinates
   * Since the same coordinates can belong to multiple text,
   * for each set of coords a new object is created
   *
   * @param block
   * @param textId
   * @param section
   */
  function registerCords(block, textId, section) {
    if (block.coords) {
      iterable(block.coords).forEach((c) => {
        c.id = getCoordsId(c);
        if (!coords.hasOwnProperty(c.id)) {
          c.identifier = c.id;
          c.groups = [];
          c.section = section;
          coords[c.id] = c;
        }
        if (coords[c.id].groups.indexOf(textId) === -1) {
          coords[c.id].groups.push(textId);
        }
      });
    }
  }

  /*
   * If we have a page content calculate coordinated
   */
  if (!isEmpty(pageContent) && pageContent.hasOwnProperty('section')) {

    // Iterate over the sections in case of multiple
    let sec = 0;
    iterableSections(pageContent).forEach((section, sid) => {
      iterable(section[sectionName]).forEach((subSection, tid) => {
        iterable(subSection.block).forEach((block, bid) => {
          registerCords(block, textPrefix + sid + tid + bid, sec);
        });
      });
      sec++;
    });
  }
  return coords;
}


/**
 *
 * @param pageContent
 * @returns {{coordinates: *[]}}
 */
function useCoordinates(pageContent) {

  const coordinates = [
    getCoordsData(pageContent, 'transcription'),
    getCoordsData(pageContent, 'annotation'),
    getCoordsData(pageContent, 'additionalInfo')
  ];

  return {
    coordinates
  }

}

export default useCoordinates;

export {getCoordsData};
