import {Link} from "react-router-dom";
import {isString} from "lodash";
import {useContext, useEffect, useState} from "react";
import {ContentContext} from "../../contexts/Content";
import Config from "../../helpers/Config";


/**
 *
 * @param realPageNum
 * @param imgLocation
 * @returns {JSX.Element}
 */
function Thumbnail({realPageNum, imgLocation}) {

  const imageStore = Config('IMAGE_HOST') + '/thumbs/';

  return (
    <img
      alt={'page ' + realPageNum.replace(/[^\d]/g, '')}
      src={imageStore + imgLocation}
      width={82} height="109"
    />
  )
}

/**
 *
 * @param page
 * @param currentPageId
 * @returns {JSX.Element}
 */
function ThumbsList({bookIndex, currentPageId}) {
  return (
    <ul>
      {bookIndex.map((pageInfo, index) => {
        const {leafNum, folio} = pageInfo;
        const pageId = parseInt(leafNum) + 1;
        return (
          <li key={index} className={pageId === currentPageId ? 'tb-active' : 'tb'}>
            <Link to={`/page/${pageId}`}>
              <span>{isString(folio) ? folio : ''}</span>
            </Link>
            <Thumbnail {...pageInfo} />
          </li>
        )
      })}
    </ul>
  );
}

/**
 * Bottom Thumb scroll component
 *
 *
 * @returns {JSX.Element}
 */
function ThumbScroll() {

  const {indexIsLoading, indexData, pageId} = useContext(ContentContext);

  const thumbWidth = 90;
  const scrollerWidth = indexData.length * thumbWidth;
  const [thumbSelectStyle, setThumbSelectStyle] = useState({
    width: scrollerWidth,
    transition: '1s'
  });


  useEffect(() => {

    const halfScreenWidth = document.getElementById('thumb-wrapper').offsetWidth / 2;

    // Thumb select is at the right end
    if (scrollerWidth - ((pageId - 1) * thumbWidth) < halfScreenWidth) {
      setThumbSelectStyle(style => ({
        ...style,
        left: `-${scrollerWidth - halfScreenWidth}px`,
      }))
    }
    // ThumbSelect is moving
    else if ((pageId * thumbWidth) > halfScreenWidth) {
      setThumbSelectStyle(style => ({
        ...style,
        left: `-${thumbWidth * (pageId - 0.5)}px`,
      }))
    }
    // At the beginning:
    else {
      setThumbSelectStyle(style => ({
        ...style,
        left: `-${halfScreenWidth}px`,
      }));
    }
  }, [pageId, scrollerWidth]);


  if(indexIsLoading) return <div className="thumb-select">..loading</div> ;

  return (
    <div className="thumb-select">
      <div id="thumb-wrapper" style={{left: '50%'}}>
        <div id="thumb-select" style={thumbSelectStyle}>
          <ThumbsList bookIndex={indexData} currentPageId={pageId}/>
        </div>
      </div>
    </div>
  )
}

export default ThumbScroll;
