import React, {createContext, useEffect, useState} from "react";
import {normalizeContent} from "../hooks/useBookPageData";
import {getCoordsData} from "../hooks/useCoordinates";
import useBookIndexData from "../hooks/useBookIndexData";
import  {getPage} from "../services/ObjectsApi";

const ContentContext = createContext(undefined);

const CONTENT_TYPE = {
  TRANSCRIPTIONS: 0,
  ANNOTATIONS: 1,
  ADDITIONAL_INFO: 2
};

function Content({children, pageId}) {


  const [activeTab, setActiveTab] = useState(CONTENT_TYPE.ADDITIONAL_INFO);

  const [content, setContent] = useState({
    pageId: 1,
    leafNum: 0,
    pageContent: {},
    coordinates: [{}, {}, {}],
    activeTab
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const {indexData, indexIsLoading} = useBookIndexData();


  useEffect(() => {


    async function getContent() {
      try {
        const response = await getPage(pageId);
        // debugger;
        const pageContent = normalizeContent(response.data);
        const coordinates = [
          getCoordsData(pageContent, 'transcription'),
          getCoordsData(pageContent, 'annotation'),
          getCoordsData(pageContent, 'additionalInfo')
        ]

        setContent({
          pageId: pageId,
          leafNum: pageId - 1,
          pageContent: pageContent,
          coordinates: coordinates,
          activeTab: activeTab
        })

      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }

    getContent();

  }, [pageId, activeTab])

  if (error) throw error;


  return (
    <ContentContext.Provider value={{
      indexData,
      indexIsLoading,
      pageId,
      loading,
      content,
      setActiveTab
    }}>
      {children}
    </ContentContext.Provider>
  )
}


export {Content, ContentContext, CONTENT_TYPE};
