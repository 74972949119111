import {Tabs, Tab} from "react-bootstrap";
import {iterable} from "../../helpers/Utils";
import {isString} from "lodash";
import {useState} from "react";
import TextAndCoordsBlock from "./TextAndCoordsBlock";


function TextBlock({text}) {
  return (
    <span>{text}</span>
  )
}


function TranscriptionText({trIndex, textBlocks, secIndex}) {

  const [selectedBlock, setSelectedBlock] = useState('');

  return (
    <div className="transcriptions vscroll new-line">
      {iterable(textBlocks).map((block, index) => {
        // let text = block.text.replace(/\r?\n/g, '<br />');
        // TODO what to do when text is an object like page 14 nl transcription
        if (isString(block.text) && block.coords) {
          return (
            <TextAndCoordsBlock
              id={`transcription-${secIndex}${trIndex}${index}`}
              key={index}
              text={block.text}
              coords={block.coords}
              selectedBlock={selectedBlock}
              setSelectedBlock={setSelectedBlock}
            />
          )
        } else if (isString(block.text)) {
          return <TextBlock
            key={index}
            text={block.text}/>
        }
        return '';
      })}
    </div>
  )
}

/**
 *
 * @param transcriptions
 * @returns {JSX.Element}
 * @constructor
 */
function Transcription({transcriptions, secIndex}) {

  const languages = {en: 'english', nl: 'nederlands', fr: 'francais', la: 'latinum'};

  return (
    <div className="transcriptions">
      <Tabs className="nav-justified">
        {/*TODO: c'e' bisogno di questo loop? */}
        {transcriptions.map((translation, index) => {

          const {lang, block} = translation;

          return (
            <Tab key={index} eventKey={lang} title={languages[lang]}>
              <TranscriptionText
                secIndex={secIndex}
                trIndex={index}
                textBlocks={block}
              />
            </Tab>
          )
        })}
      </Tabs>
    </div>
  )
}

export default Transcription;
