import {iterable} from "../../helpers/Utils";
import {isEmpty, isString} from "lodash";
import {Button} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {ContentContext} from "../../contexts/Content";
import Config from "../../helpers/Config";

/**
 *
 * @param leafNum
 * @param handleClose
 * @returns {JSX.Element}
 * @constructor
 */
function ModalDownloadText({handleClose}) {

  const [downloading, setDownloading] = useState(false);
  const [image, setImage] = useState('');
  const [section, setSection] = useState([]);

  const {indexData, content} = useContext(ContentContext);
  const {pageContent, leafNum} = content;


  useEffect(()=> {
    if(!isEmpty(indexData)) {
      const imgLocation = indexData[leafNum].imgLocation;
      setImage(imgLocation);
      setSection(pageContent);
    }

  },[indexData, pageContent, leafNum]);



  /**
   *
   * @param {string} lang
   * @returns {undefined}
   **/
  function getDownloadUrl(lang) {

    let url = Config('VIEWER_URL') + '/txt/psalter_annotation_';

    return url + lang + '.txt';

  }


  /**
   * Trigger the browser download by creating a link and clicking it
   *
   * @param lang
   */
  function download(lang) {
    const a = document.createElement('a');
    //a.href        = 'data:attachment/csv,' + csvString;

    a.href = getDownloadUrl(lang);
    //a.target = '_blank';
    a.download = 'psalter_annotation_' + lang + '.txt';
    a.type = 'text/plain';
    document.body.appendChild(a);
    a.click();
  }

  /**
   *
   * @param lang
   */
  function downloadAnnotation(lang) {
    setDownloading(true);
    setTimeout(()=>{
      download(lang);
      handleClose();
    }, 3000);
  }

  if(isEmpty(image)) return <div>.. loading</div>;


  if(downloading) {
    return (
      <div className="download-starting fa-3x" >
        <i className="fas fa-circle-notch fa-spin" />
        <p>download is starting ...</p>
      </div>
    );
  }

  return (
    <div className="download-buttons">
      <p>
      <Button onClick={() => downloadAnnotation('nl')}>NL</Button>
        Nederlands
      </p>
      <p>
      <Button onClick={() => downloadAnnotation('en')}>EN</Button>
        English
      </p>
      <p>
      <Button onClick={() => downloadAnnotation('fr')}>FR</Button>
        Francais
      </p>
      <p>
      <Button onClick={() => downloadAnnotation('lt')}>LT</Button>
        Latin
      </p>
    </div>
  );
}



export default ModalDownloadText;
