import {Link} from "react-router-dom";
import {useContext} from "react";
import {ContentContext} from "../contexts/Content";

/**
 * Simple arrow navigation to browse the psalter pages
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ArrowsNavigation = () => {

  const {indexIsLoading, indexData, pageId} = useContext(ContentContext)

  if (indexIsLoading) return <div className="nav-buttons" />

  return (
    <div className="nav-buttons">
      {pageId > 1 &&
      <Link to={`/page/${pageId - 1}`} className="button-left">
        <i className="fa fa-chevron-left fa-2x"/>
      </Link>
      }
      {pageId < (indexData.length) &&
      <Link to={`/page/${pageId + 1}`} className="button-right">
        <i className="fa fa-chevron-right fa-2x"/>
      </Link>
      }
    </div>
  )
}

export default ArrowsNavigation;
