import {isEmpty, isString, toString} from "lodash";
import {iterable} from "../../helpers/Utils";
import {useContext, useEffect, useState} from "react";
import {ContentContext} from "../../contexts/Content";
import {useNavigate} from "react-router-dom";



function NavigationSelect() {

  const {indexData, pageId} = useContext(ContentContext);
  const navigate = useNavigate();

  /*
    Index of pages contained in each section:
    - pages : {leafNum: pageLabel }
   */

  const [sectionIndex, setSectionIndex] = useState({
    folio: {name: 'folio', pages: {}},
    psalms: {name: 'psalms', pages: {}},
    canticum: {name: 'canticum', pages: {}},
  });

  const [selectedSection, setSelectedSection] = useState();


  /**
   * Select the section to make its pages visible in the "Page" select
   *
   * @param e
   */
  function selectSection(e) {
    setSelectedSection(sectionIndex[e.target.value].pages);
  }

  /**
   * When selecting a page the app navigates to the specific page
   * @param e
   */
  function navigateToPage(e) {
    const pageId = parseInt(e.target.value) + 1;
    navigate(`/page/${pageId}`);
  }


  useEffect(() => {

    if (!isEmpty(indexData)) {

      /**
       * Book index ordered by section
       *
       * Folio: contains all the pages (recto/verso)
       * Sections: Psalms, Cantica
       *
       * Iterates over the book index and divides it in section
       */


      let sIdx = (sectionIndex);

      indexData.forEach(p => {

        const {leafNum, folio} = p;
        // should be a string
        if (isString(folio)) {

          // 1. We add the page in the folio sections that contains all the pages
          //    one page par folio
          sIdx.folio.pages[leafNum] = folio;

          // 2. we add psalms and canticum
          //    one page can contain multiple psalms or canticum
          iterable(p.section).forEach(s1 => {

            if (s1.hasOwnProperty('label') && isString(s1.label)) {
              let optionLabel = [];
              iterable(s1.section).forEach(s2 => {
                const {label} = s2;
                if (isString(label)) {
                  optionLabel.push(
                    label.substring(label.indexOf(' ') + 1, label.length)
                  );
                }
              });

              if (s1.label === 'Psalms') {
                sIdx.psalms.pages[leafNum] = optionLabel.join(' - ');
              } else if (s1.label === 'Cantica') {
                sIdx.canticum.pages[leafNum] = optionLabel.join(' - ');
              }
            }
          });

        }
      });

      setSectionIndex(sIdx);
      setSelectedSection(sIdx['folio'].pages);
    }


  }, [indexData, sectionIndex])


  return (
    <form>
      <div className="row mb-3 align-items-center">
        <div className="col-sm-2">
          <label>Section:</label>
        </div>
        <div className="col-sm-8">
          <select id="pageSelect" className="form-select" onChange={selectSection}>
            {Object.entries(sectionIndex).map(([key, value], index) => {
              return <option key={index} value={key}>{value.name}</option>
            })}
          </select>
        </div>
      </div>
      <div className="row mb-3 align-items-center">
        <div className="col-sm-2">
          <label>Page:</label>
        </div>
        <div className="col-sm-8">
          {selectedSection &&
          <select className="form-select" defaultValue={toString(pageId - 1)} onChange={navigateToPage}>
            {Object.entries(selectedSection).map(([leafNumIdx, label], i) => {
              return <option key={i} value={leafNumIdx}>{label}</option>
            })}
          </select>
          }
        </div>
      </div>
    </form>
  )
}

export default NavigationSelect;
