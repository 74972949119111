import _ from "lodash";

const REQUEST_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  FAILURE: "failure"
}


/**
 * Return any type of passed value as an array
 *
 * @param value
 * @returns {*[]|*}
 */
function iterable(value) {
  if (_.isArray(value)) {
    return value;
  } else if (_.isObject(value) || _.isString(value) || _.isNumber(value)) {
    return [value];
  }
  return [];
}

/**
 * Returns an array of sections that can be passed to any forEach
 * this because the section value of the page metadata can have very
 * inconsistent value : string, array, object
 *
 * @param data
 * @returns {Array} o f data.section.section
 */
function iterableSections(data) {
  let iSections = [];
  //
  iterable(data.section).forEach(section => {
    if (section.section) {
      iterable(section.section).forEach(section => {
        iSections.push(section);
      });
    }
  });

  return iSections;
}


export {REQUEST_STATUS, iterable, iterableSections};
