import {useContext} from "react";
import {ContentContext} from "../contexts/Content";
import ThumbScroll from "./PanelBottom/ThumbScroll";
import PanelBottomControls from "./PanelBottom/PanelBottomControls";


/**
 *
 * @returns {JSX.Element}
 *
 */
function PanelBottom() {

  const {indexIsLoading} = useContext(ContentContext);

  if (indexIsLoading)
    return (
      <div className="panel panel-bottom">
        <div className="loading">loading ..</div>
      </div>
    );

  return (
    <div className="panel panel-bottom">
      <div className="panel-content">
        <PanelBottomControls/>
        <ThumbScroll/>
      </div>
    </div>

  );

}

export default PanelBottom
