import {Tab, Tabs} from 'react-bootstrap';
import {useContext} from "react";
import {LayoutContext} from "../contexts/Layout";
import {ContentContext} from "../contexts/Content";
import ContentTabs from "./PanelRight/ContentTabs";


/**
 *
 * @returns {JSX.Element|string}
 */
function PanelRight() {


  const {setActiveTab, loading, content} = useContext(ContentContext);
  const {pageContent, coordinates, activeTab} = content;

  const {displayPanelRight, togglePanelRight} = useContext(LayoutContext);


  const hidePanelClass = !displayPanelRight ? 'closed' : 'open';

  //TODO
  function selectSectionTab(tabId) {
    // console.log('SectionTab:', tabId)
  }

  /**
   *
   * @param typeId
   */
  function selectContentTypeTab(typeId) {
    setActiveTab(typeId);
    updateCoordinates(typeId);
  }

  /**
   *
   * @param tabId
   */
  function updateCoordinates(tabId) {
    const cords = coordinates[tabId]
    if (window.iipviewer)
      window.iipviewer.updateAnnotations(cords);
  }

  // const hasContent = (section) => {
  //   return section.every((s) => {
  //     return 'annotation' in s
  //       || 'transcription' in s
  //       || 'additionalInfo' in s;
  //   })
  //
  // }

  if (loading) return <div className={'panel panel-right'}/>

  //TODO hide panel if there is no content at all

  // if (!hasContent(pageContent.section.section)) {
  //   return <div className={'panel panel-right'}/>;
  // }


  return (
    <div className={`panel panel-right ${hidePanelClass}`}>
      <i className="close-btn panel-control" onClick={togglePanelRight}/>
      <div className="panel-content">

        <Tabs defaultActiveKey={0} onSelect={selectSectionTab} className="section nav-fill">

          {pageContent.section.section.map((oneSection, index) => {

            const {label} = oneSection;

            return (
              <Tab key={index} title={label} eventKey={index}>
                <div className="tab-content">
                  <ContentTabs index={index}
                               activeTab={activeTab}
                               selectContentTypeTab={selectContentTypeTab}
                               {...oneSection} />
                </div>
              </Tab>
            )
          })}
        </Tabs>
      </div>
    </div>

  )
}

export default PanelRight;
