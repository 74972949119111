import {Link} from "react-router-dom";


function Intro() {


  return (
    <>
      <div id="cover-unesco">
        <a
          href="https://www.uu.nl/en/utrecht-university-library-special-collections/the-treasury/manuscripts-from-the-treasury/the-utrecht-psalter"
          target="_blank"
          rel="noreferrer"
        >
          <img alt="Unesco Logo"
               src="../images/unesco.gif"
               className="img-fluid"/>
        </a>
      </div>
      <Link to="/page/1">
        <div id="cover">
          <div id="cover-title">
            <h2>The annotated Utrecht Psalter</h2>
          </div>
          <div id="cover-image"/>
          <div id="cover-message">
            <p>Discover and explore a unique manuscript that has been added to the UNESCO Memory of the World Register
              for
              Documentary Heritage</p>
          </div>

        </div>
      </Link>

    </>
  )
}

export default Intro;
