import TextBlock from "./TextBlock";

function Annotation({block}) {

  // const {annotation} = section;
  if (block) {
    return (
      <div className="vscroll">
        <TextBlock text={block.text}/>
      </div>
    )
  }
}

export default Annotation;
