import Config from "../helpers/Config";
import axios from "axios";


const apiUrl = Config('OBJECTS_API');

function getIndex() {
  return axios.get(`${apiUrl}&mode=3&page=index&format=json`);
}


function getPage(pageId) {
  return axios.get(`${apiUrl}&mode=3&page=${pageId}&format=json`);
}


export  {
  getPage,
  getIndex,
}
