import React from 'react';
import './styles/main.scss';
import {Route, Routes} from "react-router-dom";
import Psalter from "./components/Psalter";
import Intro from "./components/Intro";
import PageNotFound from "./components/PageNotFound";


function App() {

  return (
    <Routes>
      <Route path="/"  element={<Intro />}/>
      <Route path="/page/:pageId" element={<Psalter />}/>
      <Route path="/404" element={<PageNotFound />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
