import {Modal, Button} from "react-bootstrap";
import {useState} from "react";
import {
  ButtonSearch, ButtonInfo, ButtonShare,
  ButtonDownloadImage, ButtonFullScreen, ButtonDownloadText
} from './PanelTop/TopButtons'


/**
 *
 * @param indexInfo
 * @param fullScreenHandle
 * @returns {JSX.Element}
 * @constructor
 */
function PanelTop({fullScreenHandle}) {

  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [size, setSize] = useState('md');




  const handleClose = () => setShow(false);

  const handleShow = (title, body, size = 'md') => {
    setTitle(title);
    setBody(body);
    setShow(true);
    setSize(size);
  }


  return (
    <>
      <Modal show={show} onHide={handleClose} size={size}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'info-text'}>{body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div id="panel-top">
        <ul>
          <li>
            <ButtonShare handleShow={handleShow}
                         handleClose={handleClose}/>
          </li>
          <li>
            <ButtonDownloadImage handleShow={handleShow}
                                 handleClose={handleClose} />
          </li>
          <li>
            <ButtonDownloadText handleShow={handleShow}
                                 handleClose={handleClose} />
          </li>
          <li>
            <ButtonFullScreen fullScreenHandle={fullScreenHandle}/>
          </li>
          <li>
            <ButtonInfo handleShow={handleShow}/>
          </li>
          {/*<li>*/}
          {/*  <ButtonSearch handleShow={handleShow} handleClose={handleClose}/>*/}
          {/*</li>*/}
        </ul>
      </div>
    </>

  )
}

export default PanelTop;
