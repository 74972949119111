import {useContext, useState} from "react";
import {LayoutContext} from "../../contexts/Layout";
import ModalNavigation from "./ModalNavigation";

function PanelBottomControls() {


  const {togglePanelBottom, displayPanelBottom} = useContext(LayoutContext);

  const classClosed = !displayPanelBottom ? 'closed' : '';

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const handleShowModal = () => {
    setShowModal(true);
  }

  return (
    <div className={`panel-bottom-controls ${classClosed}`}>
      <div className="row">
        <div className="col">
          <i className="close-btn" onClick={togglePanelBottom} />
        </div>
        <div className="col">
          <i className="fa fa-external-link-square-alt" onClick={handleShowModal} />
        </div>
      </div>
      <ModalNavigation show={showModal} hideModal={handleCloseModal}/>
    </div>
  )
}

export default PanelBottomControls;
