import {Tab, Tabs} from "react-bootstrap";
import {GeneralInfo, AboutInfo} from "./StaticContent";


function ModalInfo(){
  return (
    <Tabs defaultActiveKey='general' className={'info-nav-link'}>
      <Tab eventKey={'general'} title='General information' >
        {GeneralInfo}
      </Tab>
      <Tab eventKey={'about'} title='About this edition'>
        {AboutInfo}
      </Tab>
    </Tabs>
  )
};



export default ModalInfo;
