import TextBlock from "./TextBlock";
import {iterable} from "../../helpers/Utils";
import TextAndCoordsBlock from "./TextAndCoordsBlock";
import {useState} from "react";

function AdditionalInformation({block, secIndex}) {

  const [selectedBlock, setSelectedBlock] = useState('');

  //There are no translation so the translation index is always 0
  const trIndex = 0;

  if (block) {
    return (
      <div className="vscroll new-line">
        {iterable(block).map((oneBlock, index) => {
          if (oneBlock.coords) {
            return (
              <TextAndCoordsBlock
                id={`transcription-${secIndex}${trIndex}${index}`}
                key={index}
                text={oneBlock.text}
                coords={oneBlock.coords}
                selectedBlock={selectedBlock}
                setSelectedBlock={setSelectedBlock}
              />
            );
          } else {
            return <TextBlock key={index} text={oneBlock.text}/>;
          }
        })}
      </div>

    )
  }
}

export default AdditionalInformation;
