import {useEffect, useState} from "react";
import _ from "lodash";
import {iterable} from "../helpers/Utils";
import Config from "../helpers/Config";

/**
 * Normalize page content structure between pages
 *
 * @param {*} pageContent
 * @returns {*}
 */
function normalizeContent(pageContent) {


  if (!_.isObject(pageContent) || !_.has(pageContent, 'section')) return [];

  /**
   *  1. Transform section in an array because some pages have more than one psalm
   *     See page 12
   */
  pageContent.section.section = iterable(pageContent.section.section);


  /**
   *  1. make transcription iterable
   *  2. remove empty transcriptions
   */
  pageContent.section.section.forEach(sec => {
    if (_.has(sec, 'transcription')) {
      // TODO find where transcription is not an array
      sec.transcription = iterable(sec.transcription);
      // keep only transcriptions that have a block property
      sec.transcription = sec.transcription.filter(t => t.block);
    }

    /**
     * 4. Remove empty annotation
     */
    if (
      _.has(sec, 'annotation')
      && !_.isEmpty(sec.annotation)
      && !sec.annotation.block
    ) {
      sec.annotation = null;
    }

    /**
     * 4. Remove empty additionalInfo
     */
    if (
      _.has(sec, 'additionalInfo')
      && !_.isEmpty(sec.additionalInfo)
      && !sec.additionalInfo.block
    ) {
      sec.additionalInfo = null;
    }

    /**
     * 3. Check the section title
     * @type {string}
     */
    sec.label = _.isEmpty(sec.label) ? '' : sec.label;

  });

  /**
   *
   */


  return pageContent;
}


/**
 * - Initialize the APP state fetching content from the ObjectsAPI
 * - updating current page data
 * - update IIPMooViewer
 *
 *
 */

function useBookPageData(pageId) {


  // const [requestStatus, setRequestStatus] = useState(REQUEST_STATUS.LOADING);
  const [error, setError] = useState(null);
  const [pageContent, setPageContent] = useState({});


  useEffect(() => {

    const apiUrl = Config('OBJECTS_API');

    fetch(`${apiUrl}&mode=3&page=${pageId}&format=json`)
      .then(res => res.json())
      .then(
        res => {
          const pc = normalizeContent(res);
          setPageContent(pc);
        },
        err => {
          setError(err);
        });
  }, [pageId])

  return {
    pageContent, error
  }
}

export default useBookPageData;
export {normalizeContent};

// export {REQUEST_STATUS} ;
