import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {Layout as LayoutContext} from "../contexts/Layout";
import {Content as ContentContext} from "../contexts/Content";
import PanelBottom from "./PanelBottom";
import Viewer from "./Viewer";
import PanelTop from "./PanelTop";
import PanelRight from "./PanelRight";
import ArrowsPagination from "./ArrowsPagination";
import {useParams} from "react-router-dom";
import React from "react";
import PageNotFound from "./PageNotFound";

function Psalter() {

  const fullScreenHandle = useFullScreenHandle();

  let {pageId} = useParams();

  // Redirect to the first page if not a number
  const pattern = /^[0-9]+$/
  if(!pattern.test(pageId)) return (<PageNotFound />);

  // Redirect if out of range
  pageId = parseInt(pageId);
  if(pageId <1 || pageId > 226) return (<PageNotFound />);


  return (
    <FullScreen handle={fullScreenHandle}>
      <LayoutContext>
          <ContentContext pageId={pageId}>
            <Viewer/>
            <PanelTop fullScreenHandle={fullScreenHandle}/>
            <PanelRight/>
            <ArrowsPagination/>
            <PanelBottom/>
          </ContentContext>
      </LayoutContext>
    </FullScreen>
  );
}

export default Psalter;
