import {Button, Modal} from "react-bootstrap";
import NavigationSelect from "./NavigationSelect";

function ModalNavigation({show, hideModal}) {

  return (
    <Modal show={show} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Navigate to page</Modal.Title>
      </Modal.Header>
      <Modal.Body className={'info-text'}>
        <NavigationSelect />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={hideModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalNavigation;
