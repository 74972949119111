import {iterable} from "../../helpers/Utils";
import {isEmpty, isString} from "lodash";
import {Button} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {ContentContext} from "../../contexts/Content";
import Config from "../../helpers/Config";

/**
 *
 * @param leafNum
 * @param handleClose
 * @returns {JSX.Element}
 * @constructor
 */
function ModalDownloadImage({handleClose}) {

  const [downloading, setDownloading] = useState(false);
  const [image, setImage] = useState('');
  const [section, setSection] = useState([]);

  const {indexData, content} = useContext(ContentContext);
  const {pageContent, leafNum} = content;


  useEffect(()=> {
    if(!isEmpty(indexData)) {
      const imgLocation = indexData[leafNum].imgLocation;
      setImage(imgLocation);
      setSection(pageContent);
    }

  },[indexData, pageContent, leafNum]);


  const currentPageLabel = function() {
    let label = [];

    const subSections = iterable(section.section);

    if(isEmpty(section.label) || (isEmpty(subSections[0].label))) {
      return 'page ' + parseInt(leafNum) + 1;
    }
    if(isString(section.label)) {
      label.push(section.label);
    }
    iterable(subSections).forEach(section => {
      if(isString(section.label)) {
        return label.push(section.label)
      }
    })
    return label.join('-');
  };


  const fileName =  'Utrechts-Psalter_' +
    currentPageLabel().replace(/[\s]+/g, '-') + '.jpg';


  /**
   * Description gets img url and size to offer a download image.
   * using indexOf which is not available in IE lt 9
   *
   * @param {string} size
   * @returns {undefined}
   **/
  function getDownloadUrl(size) {

    let url = Config('IMAGE_DOWNLOAD') + '?f=';

    switch (size) {
      default:
      case 'full':
        //tiff image
        url += image;
        break;
      case 'large':
        url += image.replace('.jpg', '_c60.jpg');
        break;
      case 'small':
        url += image.replace('.jpg', '_800.jpg');
        break;
    }
    return url + '&n=' + fileName;

  }


  /**
   * Trigger the browser download by creating a link and clicking it
   *
   * @param fileSize
   */
  function download(fileSize) {
    const a = document.createElement('a');
    //a.href        = 'data:attachment/csv,' + csvString;
    a.href = getDownloadUrl(fileSize);
    //a.target = '_blank';
    a.download = fileName;
    a.type = 'image/jpeg';
    document.body.appendChild(a);
    a.click();
  }

  /**
   *
   * @param size
   */
  function downloadImage(size) {
    setDownloading(true);
    setTimeout(()=>{
      download(size);
      handleClose();
    }, 3000);
  }

  if(isEmpty(image)) return <div>.. loading</div>;


  if(downloading) {
    return (
      <div className="download-starting fa-3x" >
        <i className="fas fa-circle-notch fa-spin" />
        <p>download is starting ...</p>
      </div>
    );
  }

  return (
    <div className="download-buttons">
      <p>
      <Button onClick={() => downloadImage('small')}>Small</Button>
        <b>Image type</b>: jpeg, <b>size</b>: 800 x 1070px ca., 350 Kb
      </p>
      <p>
      <Button onClick={() => downloadImage('large')}>Large</Button>
        <b>Image type</b>: jpeg, <b>size</b>: 4400 x 5900px ca., 4,1 MB
      </p>
      <p>
      <Button onClick={() => downloadImage('full')}>Full</Button>
        <b>Image type</b>: jpeg, <b>size</b>: 4400 x 5900px ca., 11 MB
      </p>
    </div>
  );
}



export default ModalDownloadImage;
