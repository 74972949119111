import {iterable} from "../../helpers/Utils";

/**
 *
 * @param id
 * @param text
 * @param coords
 * @param selectedBlock
 * @param setSelectedBlock
 * @returns {JSX.Element}
 */
function TextAndCoordsBlock({id, text, coords, selectedBlock, setSelectedBlock}) {

  /**
   * Select and highlight the text
   * @param textId
   */
  function selectText(textId) {
    setSelectedBlock(textId);
    selectCoords(textId)
  }

  /**
   * Ask iip to select coords canvas on the image
   * @param e
   */
  function selectCoords(textId) {
    if(window.iipviewer)
      window.iipviewer.highlightCoordsCanvas(textId);
  }


  /**
   * Transform the coords in a classname
   *
   * @param coords
   * @returns {string}
   */
  function getCoordsClass(coords) {
    let className = [];
    //Cords can be an array or an object
    iterable(coords).forEach(crd => {
      className.push(`annotation-${(crd.x + crd.y + crd.w + crd.h).replace(/\./g, '')}`);
    });
    return className.join(' ');
  }

  const coordsClass = getCoordsClass(coords);
  const selectedClass = (id === selectedBlock) ? 'coordinates-selected' : ''

  return (
    <span id={id}
          className={`coordinates ${coordsClass} ${selectedClass}`}
          onClick={() => selectText(id)}
          dangerouslySetInnerHTML={{__html : text}}
    />
  )
}

export default TextAndCoordsBlock;
