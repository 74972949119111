import {createContext, useState} from "react";

const LayoutContext = createContext(undefined);


function Layout({children}) {

  const [displayPanelRight, setDisplayPanelRight] = useState(true);
  const [displayPanelBottom, setDisplayPanelBottom] = useState(true);


  function togglePanelRight() {
    setDisplayPanelRight(!displayPanelRight);
  }

  function togglePanelBottom() {
    setDisplayPanelBottom(!displayPanelBottom);
  }

  const prClass = displayPanelRight ? 'with-panel-right' : '';
  const pbClass = displayPanelBottom ? 'with-panel-bottom'  : '';

  return (
    <LayoutContext.Provider
      value={{displayPanelRight, togglePanelRight, displayPanelBottom, togglePanelBottom}}>
      <div className={`App main ${prClass} ${pbClass} annotations`}>
        {children}
      </div>
    </LayoutContext.Provider>

  );
}

export  {Layout, LayoutContext};

