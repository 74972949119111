import {useContext, useEffect} from "react";
import {ContentContext} from "../contexts/Content";
import {isEmpty} from "lodash";
import Config from "../helpers/Config";


function ZoomButton() {

  const {iipviewer} = window;

  function zoomIn() {
    iipviewer.zoomIn();
  }

  function zoomOut() {
    iipviewer.zoomOut();
  }

  return (
    <div className="zoom">
      <div className="zoom-buttons">
        <i onClick={zoomIn} className="button-left fa fa-plus"/>
        <i onClick={zoomOut} className="button-right fa fa-minus"/>
      </div>
    </div>
  )
}

/**
 * The only purpose of this component is to start the
 * IIP viewer from within the react app so that
 * - will be started when the component is mounted
 * - will be handled correctly from the fullScreen component
 *
 * @returns {JSX.Element}
 */
function Viewer() {


  const { indexData, content, loading} = useContext(ContentContext);


  useEffect(() => {

    if (!loading && !isEmpty(indexData) ) {

      const {leafNum, coordinates, activeTab} = content;

      const imgLocation = indexData[leafNum].imgLocation;
      const image = `/manifestation/viewer${imgLocation.replace('jpg', 'jp2')}`;
      const coords = coordinates[activeTab]

      if (window.iipviewer) {
        window.iipviewer.changeImage(image, coords);
      } else {

        const iipApi = Config('IIP_API');
        window.iipviewer = window.startViewer(image, coords, iipApi);
      }
    }
  }, [ indexData, loading, content])


  return (
    <div id="viewer">
      <ZoomButton/>
    </div>
  )
}

export default Viewer;



