import {CONTENT_TYPE} from "../../contexts/Content";
import {isEmpty} from "lodash";
import {Nav, Tab} from "react-bootstrap";
import Transcription from "./Transcription";
import Annotation from "./Annotation";
import AdditionalInformation from "./AdditionalInformation";

function ContentTabs({index, activeTab, selectContentTypeTab, transcription, annotation, additionalInfo}) {


  return (
    <Tab.Container defaultActiveKey={activeTab}
                   onSelect={selectContentTypeTab}>

      {/* Tabs navigation */}
      <Nav className="nav-justified nav-tabs" as={"ul"}>
        {!isEmpty(transcription) &&
        <Nav.Item as="li">
          <Nav.Link eventKey={CONTENT_TYPE.TRANSCRIPTIONS} title={'Text'}>
            <i className="fa fa-file-alt"/>
          </Nav.Link>
        </Nav.Item>
        }
        {annotation &&
        <Nav.Item as="li">
          <Nav.Link eventKey={CONTENT_TYPE.ANNOTATIONS} title={'Image description'}>
            <i className="fa fa-comment"/>
          </Nav.Link>
        </Nav.Item>
        }
        {additionalInfo &&
        <Nav.Item as="li">
          <Nav.Link eventKey={CONTENT_TYPE.ADDITIONAL_INFO} title={'Additional information'}>
            <i className="fa fa-pen-square"/>
          </Nav.Link>
        </Nav.Item>
        }
      </Nav>

      {/* Content of the Tabs */}
      <Tab.Content>

        {!isEmpty(transcription) &&
        <Tab.Pane eventKey={CONTENT_TYPE.TRANSCRIPTIONS} title="Transcription">
          <Transcription className="vscroll" transcriptions={transcription} secIndex={index}/>
        </Tab.Pane>
        }

        {annotation &&
        <Tab.Pane eventKey={CONTENT_TYPE.ANNOTATIONS} title="Annotation">
          <Annotation {...annotation}/>
        </Tab.Pane>
        }

        {additionalInfo &&
        <Tab.Pane eventKey={CONTENT_TYPE.ADDITIONAL_INFO} title="Additional Information">
          <AdditionalInformation {...additionalInfo} secIndex={index}/>
        </Tab.Pane>
        }
      </Tab.Content>

    </Tab.Container>

  )
}

export default ContentTabs;
